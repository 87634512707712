@import "components/barrel";

$secondary-font: 'Lobster', cursive;

/*Non-animation and non-hiding styling*/
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

h1 {
  font-family: $secondary-font;
  margin-top: 0;
  padding-top: 40px;
}

h2 {
  display: inline-block;
  font-size: 0.8em;
  border-bottom: 1px silver solid;
}

footer {
  position: absolute;
  bottom: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 0.8em;

  .link:hover {
    text-decoration: underline;
  }
}

.row {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  /* Clear floats after the columns */
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .second {
    /* because of absolute divs that have no space */
    transform: translate(-50%, -100%);
  }
}

.column {
  float: left;
  width: 33.33%;
}

@media (max-width: 600px) {
  .blurb {
    padding: 0 50px;
  }

  .row.second {
    top: 54px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
  }

  h2 {
    padding-top: 30px;
  }

  .column {
    margin-top: 50px;
    float: none;
    width: 100%;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.selection {
  font-size: 2.2em;
}

.barrel {
  display: inline-block;
  font-family: $secondary-font;
  transform-style: preserve-3d;
  transform-origin: 0 1em;
  width: 100%;
}

.side {
  font-size: 2.2em;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  backface-visibility: hidden;

  @for $i from 0 through 5 {
    &:nth-child(#{$i + 1}) {
      transform: rotateX($i * 60deg) translateZ(1em);
    }
  }
}

.link {
  text-decoration: none;
  color: silver;
}

.blurb {
  max-width: 800px;
  margin: 0 auto;

  span {
    font-weight: bold;
  }
}

button {
  margin-top: 18px;
  border: 3px silver solid;
  background: transparent;
  padding: 10px 50px;
  color: silver;
  font-size: 30px;
  cursor: pointer;
}

/*Hiding and animation styling*/
/*Hover animation*/
.selection {
  transition: all .2s ease-in-out;

  &:hover,
  &:focus,
  &:active{
    transform: scale(0.9);
  }
}

/*Button push hiding and animation*/
.first.hidden {
  animation: fadeOut ease 1s forwards;
}

.second {
  &.hidden {
    left: -9999px;
  }

  &:not(.hidden) .column {
    opacity:0;
    animation: fadeIn ease 2s 1s forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  99% {
    left: 50%;
  }
  100% {
    left: -9999px;
    opacity:0;
  }
}

/*One-Armed Bandit animation*/
.second:not(.hidden) {
  @for $i from 0 through 2 {
    .column:nth-child(#{$i + 1}) {
      @include barrel($i);
    }
  }
}
